<template lang="html">

  <section class="organos-de-gobierno">
    <hero-global title="Gobierno" subtitle="Órganos de Gobierno" />
    <div class="container">
      <router-link :to="{ name: 'asi-nos-organizamos', params: { slug: 'organos-gobierno' } }" title="Ir a página de   Órganos de gobierno" alt="Ir a página de   Órganos de gobierno" class="btn-back">Volver
      </router-link>
    </div>
    <div class="box-content container" v-if="employee">
      <h2 class="title-grand title__double-center w-100" :data-text="employee.name">{{employee.name}}</h2>
      <img v-if="employee.image.url" :src="employee.image.url" :alt="employee.name" class="margin-auto mt-50" />
      <div class="container">
        <p class="title-dest">{{departmmentById.deparment.name}} - {{departmmentById.position}}</p>
        <p class="subtitle" v-if="employee.header">{{employee.header}}</p>
        <EditorJs :object="employee.body" />
      </div>

    </div>
    <other-links-interest class="links-interest-box container" />
  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import { contentStore } from '@/stores/contents'
  import { i18n } from '@/i18n'
  import EditorJs from "@/components/Global/editorJs.vue"
  export default {
    name: 'Government Bodies',
    props: ['id', 'idDeparment'],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      EditorJs
    },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      this.store.loadEmployees()
    },
    data() {

      return {

        popup: false,
        descriptionImageSelected: '',
        urlImageSelected: '',
        myOptions: {
          responsive: [{
            breakpoint: 900,
            settings: {
              navButtons: true,
              slidesToShow: 4,
              dots: false,
              centerMode: true,
              unagile: false,
              autoplay: false,
            },
          }, {
            breakpoint: 800,
            settings: {
              navButtons: true,
              slidesToShow: 2,
              dots: true,
              unagile: false,
              autoplay: false,
            },
          },
          {
            breakpoint: 700,
            settings: {
              navButtons: true,
              slidesToShow: 2,
              dots: true,
              unagile: false,
              autoplay: false,
            },
          }, {
            breakpoint: 0,
            settings: {
              navButtons: true,
              slidesToShow: 1,
              dots: true,
              autoplay: false,
            },
          },],
        }

      }
    },
    methods: {

    },
    computed: {
      departmmentById() {
        var deparment = null
        if (this.store.getEmployeeById(this.id)) {
          deparment = this.store.getEmployeeById(this.id).departmentSlug[this.idDeparment]
        }
        return deparment
      },
      employee() {
        var data = null
        if (this.store.getEmployeeById(this.id)) {
          data = this.store.getEmployeeById(this.id)
        }
        return data
      }
    },
    watch: {



    }
  }


</script>

<style scoped lang="scss">
  .title-dest {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
  }


  .organos-de-gobierno .title-grand.title__double-center {
    text-align: center;
  }
</style>